import { Rpc } from '@/models/Rpc';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { User, userTypes } from '@/models/User';
import { DateTime } from 'luxon';
import { StatisticsColumn } from '@/components/week-statistics-view/WeekStatisticsView';
import { ReportStatusMap, getStatusObjects } from '@/support/ReportStatus';
import { Department } from '@/models/Department';
import { StatisticsItem } from '@/components/statistics-view/StatisticsView';
import { DateFormatter } from '@/support/DateFormatter';
import { ReportType } from '@/models/ReportType';

@Component<ProductionStatistics>({})
export default class ProductionStatistics extends Vue {
  public $pageTitle = 'Ingepland per medewerker';

  protected isLoading = true;

  protected users: User[] | null = null;

  protected date = new DateFormatter();

  protected selectedType = 'reports';

  protected departments: Department[] = [];

  protected selectedDepartments: string[] = [];

  protected userTypes = userTypes;

  protected selectedUserTypes: string[] = [];

  protected selectedReportTypes: string[] = [];

  // status
  protected statusses: ReportStatusMap[] = getStatusObjects(['created', 'planning', 'werkvoorbereiding_rejected', 'opname_werkvoorbereiding_done']);

  protected selectedStatusses: string[] = [];

  // columns
  protected columns: StatisticsColumn[] = [
    {
      key: 'name',
      name: 'Naam',
      class: 'xs4 md2 lg4',
    },
    {
      key: 'week',
      name: 'Maandag',
      class: 'xs1 text-right',
      transform: (item: StatisticsItem) => `<span class="primary--text">${item.appointment_count}</span>`,
    },
    {
      key: 'week',
      name: 'Dinsdag',
      class: 'xs1 text-right',
      transform: (item: StatisticsItem) => `<span class="primary--text">${item.appointment_count}</span>`,
    },
    {
      key: 'week',
      name: 'Woensdag',
      class: 'xs1 text-right',
      transform: (item: StatisticsItem) => `<span class="primary--text">${item.appointment_count}</span>`,
    },
    {
      key: 'week',
      name: 'Donderdag',
      class: 'xs1 text-right',
      transform: (item: StatisticsItem) => `<span class="primary--text">${item.appointment_count}</span>`,
    },
    {
      key: 'week',
      name: 'Vrijdag',
      class: 'xs1 text-right',
      transform: (item: StatisticsItem) => `<span class="primary--text">${item.appointment_count}</span>`,
    },
    {
      key: 'week',
      name: 'Zaterdag',
      class: 'xs1 text-right',
      transform: (item: StatisticsItem) => `<span class="primary--text">${item.appointment_count}</span>`,
    },
    {
      key: 'week',
      name: 'Zondag',
      class: 'xs1 text-right',
      transform: (item: StatisticsItem) => `<span class="primary--text">${item.appointment_count}</span>`,
    },
    {
      key: 'row_total',
      name: 'Totaal',
      class: 'xs1 text-right',
    },
  ];

  public mounted() {
    this.date.selectedDate = DateTime.local().toFormat('yyyy-LL-dd');
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    this.fetchUsers();
    this.getDepartments();
  }

  protected getDepartments() {
    new Department()
      .all()
      .then((departments: Department[]) => {
        this.departments = departments;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected fetchUsers(): void {
    this.isLoading = true;

    const payload = {
      signature: 'planning:production-statistics',
      body: {
        date: this.date.selectedDate,
        statusses: this.selectedStatusses,
        type: this.selectedType,
        departments: this.selectedDepartments,
        user_types: this.selectedUserTypes,
        report_types: this.selectedReportTypes,
      },
    };

    new Rpc()
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.users = response.data;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Ingepland per medewerker' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
